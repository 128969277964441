import React from 'react';
import { Box } from '@mui/system';

import logoSrc from 'assets/images/logo-main.svg';
import oldLogoSrc from 'assets/images/logo-main-old.svg';
import { isActive } from 'itson/ItsOn';

interface Props {
  variant?: 'primary' | 'secondary';
}

export const Logo: React.FC<Props> = ({ variant = 'primary' }) => (
  <Box
    component="img"
    src={isActive('jlr-2445.enable_luchi_rebrand') ? logoSrc : oldLogoSrc}
    alt="Логотип"
    loading="lazy"
    style={{
      display: 'flex',
      ...(isActive('jlr-2445.enable_luchi_rebrand')
        ? { width: '200px' }
        : { justifySelf: 'flex-start', alignSelf: 'flex-start' }),
      ...(variant === 'secondary' ? { filter: 'invert(48%)' } : {}),
    }}
  />
);
