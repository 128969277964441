import React from 'react';

import { Box } from '@mui/material';

import { footerDescriptionStyle, footerLinkStyle, footerStyle } from 'ui/footer/footer';
import { getCompanyFullName } from 'utils/companyName';

export const LoginFooter: React.FC = () => (
  <Box component="footer" sx={footerStyle(true)}>
    <Box
      component="a"
      href="https://docs.bestdoctor.ru/law/Политика%20ПДн%20Бестдоктор.pdf"
      target="_blanc"
      sx={footerLinkStyle}
    >
      Политика обработки персональных данных
    </Box>
    <Box component="p" sx={footerDescriptionStyle}>
      {`2015-${new Date().getFullYear()} ${getCompanyFullName()}`}
    </Box>
  </Box>
);
