import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';
import { IconButton, List, ListItem, Badge, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

import { headerStyle, headerListStyle, headerListItemStyle } from 'ui/header/header';

import { TooltipFeedbackInfo } from 'shared/tooltip/TooltipFeedbackInfo/TooltipFeedbackInfo';
import { TooltipNotification } from 'shared/tooltip/TooltipNotification/TooltipNotification';

import { whiteColor, themeColor, grayLightDarkOpacity } from 'utils/uiConstants';

import { IHeaderProps } from 'interfaces/header/headerInterfaces';
import { Notification } from 'interfaces/notifications/notificationsInterfaces';

import { useUnviewedNotifications } from 'hooks/notifications/useUnviewedNotifications';
import { BorderLinearProgress } from 'components/Loader/Loader';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import { TooltipActionsWithSlots } from 'shared/tooltip/TooltipActionsWithSlots/TooltipActionsWithSlots';
import { useGetSlotsOperations } from 'hooks/patients/useGetSlotsOperations';
import { isActive } from 'itson/ItsOn';
import { SendRequestModal } from 'shared/modals/SendRequestModal/SendRequestModal';
import { isLoginPath } from 'utils/isLoginPath';
import { ListUserNotificationResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/notifications/types';
import { Company, DataPeriod, ErrorData } from 'interfaces/global/globalInterfaces';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { useGetAccessList } from 'hooks/permissions/useGetAccessList';
import { useGetDefaultPermissions } from 'hooks/permissions/useGetDefaultPermissions';
import { useSnackbar } from 'notistack';
import { Logo } from 'ui/Logo';

export const Header: React.FC<IHeaderProps> = ({ managersHR, handleLoggedIn, isLoggedIn }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;

  const [notificationsData, setNotificationsData] = useState<Notification[] | null>(null);

  const [isTooltipCall, setTooltipCall] = useState<boolean>(false);
  const [isTooltipWrite, setTooltipWrite] = useState<boolean>(false);
  const [isTooltipNotification, setTooltipNotification] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [slotOperationsAnchor, setSlotOperationsAnchor] = useState<HTMLElement | null>(null);
  const [isSlotsOperationsOpened, setSlotsOperationsOpened] = useState<boolean>(false);

  const [orderingActionsWithSlots, setOrderingActionsWithSlots] = useState<string>('');

  const token = localStorage.getItem('token');

  const { enqueueSnackbar } = useSnackbar();

  const { accessList } = useGetAccessList({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { defaultPermissions } = useGetDefaultPermissions({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { dataSlotsOperations, fetchNextPageSlotsOperations, isFetchingNextPageSlotsOperations } =
    useGetSlotsOperations({
      ordering: orderingActionsWithSlots,
      enabled: !!token,
      onError: (res) => {
        if (res.response.status >= 400 && res.response.status < 500) {
          res.response
            .json()
            .then((result) =>
              result.errors.forEach((item: ErrorData) =>
                enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
              )
            );
        }
      },
    });

  const { isLoadingSearchUnviewedNotifications, fetchNextPageUnviewedNotifications } = useUnviewedNotifications({
    enabled: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
    onSuccess: ({
      pageParams,
      slotsNotification,
    }: {
      pageParams: {
        pages: {
          data: ListUserNotificationResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data'][];
          meta: ListUserNotificationResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['meta'];
        }[];
        pageParams: unknown[];
      };
      slotsNotification: Notification[];
    }) => {
      if (pageParams.pages[0].meta) {
        const allPage: number = Math.ceil(pageParams.pages[0].meta.total / pageParams.pages[0].meta.per_page);
        if (pageParams.pages.length < allPage) return fetchNextPageUnviewedNotifications();

        setNotificationsData(slotsNotification);
      }
    },
  });

  const mainFilterCompaniesStaff =
    'mainFilterCompaniesStaff' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('mainFilterCompaniesStaff') || '')
      : '';

  const mainFilterCompanyFinance: Company | null = sessionStorageGetItem<Company | null>(
    'mainFilterCompanyFinance',
    null
  );

  const mainFilterCompanyPrograms: Company | null = sessionStorageGetItem<Company | null>(
    'mainFilterCompanyPrograms',
    null
  );

  const mainFilterCompanyDocuments: Company | null = sessionStorageGetItem<Company | null>(
    'mainFilterCompanyDocuments',
    null
  );

  const currentCompany: Company = useMemo(() => {
    if (path === '/staff') {
      return mainFilterCompaniesStaff;
    }
    if (path === '/finance') {
      return mainFilterCompanyFinance;
    }
    if (path === '/programs') {
      return mainFilterCompanyPrograms;
    }
    if (path === '/documents') {
      return mainFilterCompanyDocuments;
    }
  }, [mainFilterCompaniesStaff, mainFilterCompanyDocuments, mainFilterCompanyFinance, mainFilterCompanyPrograms, path]);

  const isPermissionsAviableStaff = mainFilterCompaniesStaff
    ? mainFilterCompaniesStaff
        ?.map((item: Company) =>
          isAccessAllowed(item?.uuid, 'HR_permission_configuration', accessList, defaultPermissions)
        )
        ?.some((key) => !!key)
    : false;

  const userLogin = sessionStorage.getItem('userInfo');

  const handleClickInstructions = (): void => {
    let currentPeriod;
    if (path === '/staff') {
      currentPeriod = sessionStorageGetItem<DataPeriod | null>('mainFilterPeriodStaff', null);
    }
    if (path === '/finance') {
      currentPeriod = sessionStorageGetItem<DataPeriod | null>('mainFilterPeriodFinance', null);
    }
    if (path === '/programs') {
      currentPeriod = sessionStorageGetItem<DataPeriod | null>('mainFilterPeriodPrograms', null);
    }
    if (path === '/documents') {
      currentPeriod = sessionStorageGetItem<DataPeriod | null>('mainFilterPeriodDocuments', null);
    }
    const isContractFronting = currentPeriod?.contractType === 'fronting';

    window.open(isContractFronting ? 'https://bestdoctor.ru/guide-deposit' : 'https://bestdoctor.ru/guide-risk');
  };

  return (
    <>
      {path !== '/' && path !== '/reset-password' && isLoadingSearchUnviewedNotifications && <BorderLinearProgress />}

      <Box component="header" sx={() => headerStyle(isLoginPath(path))}>
        <Logo />

        <List component="ul" sx={() => headerListStyle(path === '/')}>
          {path !== '/' && path !== '/reset-password' && (
            <>
              <ListItem>
                <Box
                  sx={{
                    minWidth: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '-16px',
                  }}
                >
                  <MainButton
                    textButton="Отправить запрос"
                    handleClick={() => window.open('https://forms.yandex.ru/cloud/63e9f1b784227c0fdf87cec3/')}
                  />
                </Box>
              </ListItem>
              {isActive('hr-330.operations_with_slots') && (
                <ListItem sx={() => headerListItemStyle(false)}>
                  <TooltipActionsWithSlots
                    anchorEl={slotOperationsAnchor}
                    open={isSlotsOperationsOpened}
                    handleTooltipClose={() => setSlotsOperationsOpened(false)}
                    notificationsData={dataSlotsOperations}
                    orderingActionsWithSlots={orderingActionsWithSlots}
                    handleOrderingActionsWithSlots={setOrderingActionsWithSlots}
                    fetchNextPageSlotsOperations={fetchNextPageSlotsOperations}
                    isFetchingNextPageSlotsOperations={isFetchingNextPageSlotsOperations}
                  >
                    <IconButton
                      aria-label="Операции с пациентами"
                      onClick={(event) => {
                        setSlotOperationsAnchor(event.currentTarget);
                        setSlotsOperationsOpened(!isSlotsOperationsOpened);
                      }}
                      disabled={isLoadingSearchUnviewedNotifications || !notificationsData}
                    >
                      <FormatListBulletedIcon
                        sx={{
                          color: themeColor,
                        }}
                      />
                    </IconButton>
                  </TooltipActionsWithSlots>
                </ListItem>
              )}
              <ListItem sx={() => headerListItemStyle(false)}>
                <TooltipNotification
                  open={isTooltipNotification}
                  anchorEl={anchorEl}
                  handleTooltipClose={() => setTooltipNotification(false)}
                  notificationsData={notificationsData || []}
                >
                  <IconButton
                    aria-label="Уведомления"
                    onClick={(event) => {
                      setTooltipNotification(!isTooltipNotification);
                      setAnchorEl(event.currentTarget);
                    }}
                    disabled={isLoadingSearchUnviewedNotifications || !notificationsData}
                  >
                    <Badge
                      sx={{ '& .MuiBadge-badge': { color: whiteColor } }}
                      badgeContent={notificationsData ? notificationsData.length : 0}
                      color="primary"
                    >
                      <NotificationsOutlinedIcon
                        sx={{
                          color:
                            isLoadingSearchUnviewedNotifications || !notificationsData
                              ? grayLightDarkOpacity
                              : themeColor,
                        }}
                      />
                    </Badge>
                  </IconButton>
                </TooltipNotification>
              </ListItem>
            </>
          )}
          {path !== '/' &&
            path !== '/reset-password' &&
            isActive('hr-474.role_model') &&
            (isPermissionsAviableStaff ||
              isAccessAllowed(currentCompany?.uuid, 'HR_permission_configuration', accessList, defaultPermissions)) && (
              <ListItem sx={() => headerListItemStyle(false)}>
                <IconButton onClick={() => navigate('/permissions')}>
                  <SupervisedUserCircleOutlinedIcon
                    sx={{
                      color: themeColor,
                    }}
                  />
                </IconButton>
              </ListItem>
            )}
          <ListItem sx={() => headerListItemStyle(isLoginPath(path))}>
            <TooltipFeedbackInfo
              open={isTooltipCall}
              titleText="Позвонить нам:"
              href="tel:88007770503"
              textContact="8 (800) 777 05 03"
              isLoggedIn={isLoggedIn}
              titleTextCurator={managersHR?.curatorPhone ? 'Врач-куратор:' : null}
              textContactCurator={managersHR?.curatorPhone ? managersHR?.curatorPhone : null}
              hrefContactCurator={managersHR?.curatorPhone ? `tel:${managersHR?.curatorPhone}` : null}
              fullNameAccount={managersHR?.accountFullName ? managersHR?.accountFullName : null}
              doctorFullName={managersHR?.doctorFullName ? managersHR?.doctorFullName : null}
              titleTextAccount={managersHR?.accountPhone ? 'Аккаунт-менеджер:' : null}
              textContactAccount={managersHR?.accountPhone ? managersHR?.accountPhone : null}
              hrefContactAccount={managersHR?.accountPhone ? `tel:${managersHR?.accountPhone}` : null}
              handleTooltipClose={() => setTooltipCall(false)}
            >
              <IconButton aria-label="Позвонить" onClick={() => setTooltipCall(!isTooltipCall)}>
                <CallIcon />
              </IconButton>
            </TooltipFeedbackInfo>
          </ListItem>
          <ListItem sx={() => headerListItemStyle(isLoginPath(path))}>
            <TooltipFeedbackInfo
              isLoggedIn={isLoggedIn}
              open={isTooltipWrite}
              titleText="Написать нам:"
              href="mailto:info@luchi.ru"
              textContact="info@luchi.ru"
              titleTextCurator={managersHR?.curatorEmail ? 'Врач-куратор:' : null}
              textContactCurator={managersHR?.curatorEmail ? managersHR?.curatorEmail : null}
              hrefContactCurator={managersHR?.curatorEmail ? `mailto:${managersHR?.curatorEmail}` : null}
              fullNameAccount={managersHR?.accountFullName ? managersHR?.accountFullName : null}
              doctorFullName={managersHR?.doctorFullName ? managersHR?.doctorFullName : null}
              titleTextAccount={managersHR?.accountEmail ? 'Аккаунт-менеджер:' : null}
              textContactAccount={managersHR?.accountEmail ? managersHR?.accountEmail : null}
              hrefContactAccount={managersHR?.accountEmail ? `mailto:${managersHR?.accountEmail}` : null}
              handleTooltipClose={() => setTooltipWrite(false)}
            >
              <IconButton aria-label="Написать" onClick={() => setTooltipWrite(!isTooltipWrite)}>
                <MailOutlineIcon />
              </IconButton>
            </TooltipFeedbackInfo>
          </ListItem>
          {path !== '/' && path !== '/reset-password' && path !== '/permissions' && (
            <ListItem sx={() => headerListItemStyle(isLoginPath(path))}>
              <IconButton
                aria-label="Инструкция"
                onClick={() => {
                  handleClickInstructions();
                }}
              >
                <HelpOutlineIcon />
              </IconButton>
            </ListItem>
          )}
          {path !== '/' && path !== '/reset-password' && (
            <ListItem
              sx={{
                paddingLeft: '0',
                paddingRight: '0',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0.02em',
                  textAlign: 'left',
                  color: '#3E414D99',
                  opacity: 0.6,
                }}
              >
                {userLogin || ''}
              </Typography>
            </ListItem>
          )}
          {path !== '/' && path !== '/reset-password' && (
            <ListItem sx={() => headerListItemStyle(path === '/')}>
              <IconButton
                aria-label="Выход"
                onClick={() => {
                  localStorage.clear();
                  sessionStorage.clear();

                  handleLoggedIn(false);

                  navigate('/');
                }}
              >
                <LogoutOutlinedIcon />
              </IconButton>
            </ListItem>
          )}
        </List>
      </Box>
      <SendRequestModal open={false} />
    </>
  );
};
