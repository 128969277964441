/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Box, Stack } from '@mui/system';
import { Dialog, Typography, IconButton } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { DetachPatientModal } from 'shared/modals/DetachPatientModal/DetachPatientModal';
import { HistoryActionsModal } from 'shared/modals/HistoryActionsModal/HistoryActionsModal';
import { EditPatientModal } from 'shared/modals/EditPatientModal/EditPatientModal';
import { TooltipActionsInfo, TooltipActionsWarning } from 'ui/tooltips/tooltipAction';
import { useCompanyActions } from 'hooks/companies/useCompanyActions';
import { RelativeSlotsModal } from 'shared/modals/RelativeSlotsModal';

import {
  modalTypographyColor,
  modalBlockRowStyle,
  modalBlockBorderStyle,
  modalBlockFooterStyle,
} from 'ui/modals/modalGlobal';

import { InfoPatientModalProps, IEventTypes, ProgramsParams } from 'interfaces/patients/patientInterfaces';
import { useSnackbar } from 'notistack';
import { useGetServicePeriods } from 'hooks/periods/useGetServicePeriods';
import { Company, DataPeriod, ErrorData } from 'interfaces/global/globalInterfaces';
import { useLoadClinicsList } from 'hooks/programs/useLoadClinicsList';
import { useGetPeriodStaffAction } from 'hooks/periods/useGetPeriodStaffAction';
import { theme } from 'ui/theme/theme';
import { getFormattedDate } from 'utils/dateHelpers';
import { useGetRelativePatients } from 'hooks/patients/useGetRelativePatients';
import { SearchRelativePatient } from 'interfaces/hooks/patientsHooksInterfaces';
import { useSendWelcomeLetter } from 'hooks/patients/useSendWelcomeLetter';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { themeColor } from 'utils/uiConstants';
import { useSearchPrograms } from 'hooks/programs/useSearchProgram';
import { moneyRowCurrentView } from 'utils/tablesMethods';
import { IDataHooksProgram, IProgram } from 'interfaces/program/programInterfaces';
import { useForceAttachPatient } from 'hooks/patients/useForceAttachPatient';
import { useCancelPatientAttach } from 'hooks/patients/useCancelPatientAttach';
import { useGetProposalDetachmentAction } from 'hooks/patients/useGetProposalDetachmentAction';
import { useCancelDetachment } from 'hooks/patients/useCancelDetachment';
import { isActive } from 'itson/ItsOn';
import { handleRefreshToken } from 'utils/refreshToken';
import { HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { getCompanyName } from 'utils/companyName';
import { getDetachTooltipTitle } from './getDetachTooltipTitle';
import { ChangeProgramModal } from '../EditPatientModal/ChangeProgramModal';
import { EarlyAttachWarning } from '../EarlyAttachWarning/EarlyAttachWarning';
import { CancelAttachWarning } from '../CancelAttachWarning/CancelAttachWarning';
import { EditProposalModal } from '../EditProposalModal/EditProposalModal';

export const InfoStaffModal: React.FC<InfoPatientModalProps> = ({
  open,
  selectedCompanies,
  patientData,
  isErrorPatient,

  handleClose,
  refetchPatient,
  isAddingForbiddenByDates,
  isDetachingForbiddenByDates,
  accessList,
  rolesData,
  defaultPermissions,
  isSlotsAviable,
  refetchSearchPatients,
}) => {
  const [isDetachPatientModal, setDetachPatientModal] = useState<boolean>(false);
  const [isEditDetachModal, setEditDetachModal] = useState<boolean>(false);
  const [isHistoryActionsModal, setHistoryActionsModal] = useState<boolean>(false);
  const [isEditPatientModal, setEditPatientModal] = useState(false);
  const [periodByAttachOrDetach, setPeriodByAttachOrDetach] = useState<DataPeriod | null>(null);
  const [slotsModalOpen, setSlotsModalOpen] = useState<boolean>(false);
  const [slotRelative, setSlotRelative] = useState<SearchRelativePatient | null>(null);
  const [isEarlyAttachWarning, setEarlyAttachWarning] = useState<boolean>(false);
  const [isCancelAttachWarning, setCancelAttachWarning] = useState<boolean>(false);
  const [isEditProposalModal, setEditProposalModal] = useState<boolean>(false);
  const [isChangeProgramModal, setIsChangeProgramModal] = useState<boolean>(false);

  // стейты для модалки истории операций
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [eventType, setEventType] = useState<IEventTypes[]>([]);
  const [orderingTableHistory, setOrderingTableHistory] = useState<string>('');

  const [programs, setPrograms] = useState<ProgramsParams[]>([]);
  const [patientProgram, setPatientProgram] = useState<ProgramsParams | null>(null);
  const [patientCompany, setPatientCompany] = useState<Company | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const mainFilterPeriodStaff =
    'mainFilterPeriodStaff' in sessionStorage ? JSON.parse(sessionStorage.getItem('mainFilterPeriodStaff') || '') : '';

  const { periodStaffAction } = useGetPeriodStaffAction({
    period: mainFilterPeriodStaff,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { handleCancelDetachment } = useCancelDetachment({
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });
  const { proposalDetachmentActionData } = useGetProposalDetachmentAction({
    uuid: patientData?.uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const {
    CompanyActionsData,
    isLoadingCompanyActions,
    hasNextPageCompanyActions,
    isFetchingNextPageCompanyActions,
    fetchNextPageCompanyActions,
    refetchCompanyActions,
  } = useCompanyActions({
    isSlotsAviable,
    companies: selectedCompanies,

    startDate,
    endDate,
    eventType,
    ordering: orderingTableHistory,

    patient: patientData?.patientUuid || '',
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { isLoadingSearchPrograms } = useSearchPrograms({
    company: patientCompany || null,
    period: mainFilterPeriodStaff,
    selectedProgram: null,
    selectedServices: [],
    selectedСity: null,

    onSuccess: (data: IDataHooksProgram) => {
      const loadProgrammArr: ProgramsParams[] | [] = data.programs
        ? data.programs.map((item: IProgram) => ({
            id: item.programUuid,
            title: `${item.programTitle}\n(страховая премия - ${moneyRowCurrentView(item.budget)})`,
            uuid: item.programUuid,
            allowedSlotPatientTypes: item.allowedSlotPatientTypes,
          }))
        : programs;

      const filterLoadProgrammArr = loadProgrammArr
        .filter((a, i) => loadProgrammArr.findIndex((s) => a.title === s.title && a.title !== '') === i)
        .sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });

      filterLoadProgrammArr.forEach((el) => {
        if (el.uuid === patientData?.program_uuid) {
          setPatientProgram(el);
        }
      });

      setPrograms(filterLoadProgrammArr);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { isLoadingServicePeriods } = useGetServicePeriods({
    companies:
      selectedCompanies && patientData
        ? selectedCompanies.filter((item) => item.uuid === patientData?.companyUuid)
        : [],
    onSuccess: (data: DataPeriod[]) => {
      data.forEach((period) => {
        if (period.activePeriod) {
          setPeriodByAttachOrDetach(period);
        }
      });
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { searchRelativePatientsData } = useGetRelativePatients({
    patient: patientData,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { handleLoadClinicsList } = useLoadClinicsList({
    programUuid: patientData?.program_uuid,
    programName: patientData?.program_uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { handleForceAddPatient } = useForceAttachPatient({
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
    onSuccess: () => {
      setEarlyAttachWarning(false);
      enqueueSnackbar('Запрос на активацию направлен, полис активируется в ближайшее время', { variant: 'success' });
    },
  });

  const { handleCancelPatientAttach } = useCancelPatientAttach({
    onSuccess: () => {
      setCancelAttachWarning(false);
      enqueueSnackbar('Запрос на отмену направлен', { variant: 'success' });
    },
  });
  const tmr = (window as any)._tmr || ((window as any)._tmr = []);

  const { handleSendWelcomeLetter } = useSendWelcomeLetter({
    slotUuid: patientData?.uuid,
    onSuccess: () => {
      enqueueSnackbar('Письмо отправлено!', { variant: 'success' });
      tmr.push({ id: '3514238', type: 'reachGoal', goal: 'patient_card_send_welcome' });
      refetchPatient();
    },
    onError: () => {
      enqueueSnackbar('При отправке письма произошла ошибка', { variant: 'error' });
    },
  });

  useEffect(() => {
    if (selectedCompanies.length) {
      selectedCompanies.forEach((company) => {
        if (patientData && company.uuid === patientData.companyUuid) {
          setPatientCompany(company);
        }
      });
    }
  }, [patientData, selectedCompanies]);

  const isPatientTypeSlot: boolean = patientData?.content_type === 'slot' || !isActive('hr-507.cross_actions');

  const isPatientTypeProposalDetach: boolean =
    proposalDetachmentActionData?.data?.base_action?.proposal_type === 'patient_detachment';

  const handleEditButtonClick = React.useCallback(() => {
    if (isPatientTypeSlot) {
      setEditPatientModal(true);
    } else {
      setEditProposalModal(true);
    }
  }, [isPatientTypeSlot]);

  const tooltipTitle = React.useMemo(() => {
    let title = '';
    if (patientData?.type === 'Завершенный') {
      title = 'Нельзя менять программу у завершенного пациента';
    }
    if (patientData?.scheduled_program_changes) {
      const date = format(new Date(patientData.scheduled_program_changes.change_at), 'dd.MM.yyyy');
      title = `Смена программы уже запланирована на  ${date}`;
    }
    if (isAddingForbiddenByDates) {
      title = 'Нет доступных дат для смены программы';
    }
    return title;
  }, [isAddingForbiddenByDates, patientData?.scheduled_program_changes, patientData?.type]);

  const handlePolicyButtonClick = async (): Promise<void> => {
    // по хорошему, надо это все вынести, но тут черти что со структурой, поэтому пока решил тут оставить
    await handleRefreshToken();
    const token = localStorage.getItem('token');

    const response = await fetch(patientData?.medPolicy ?? '', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: `${HEADER_ACCEPT_HR}`,
        'Content-Type': 'application/pdf',
      },
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  if (isErrorPatient) {
    return <p>{`Не удалось загрузить страницу. ${isErrorPatient}`}</p>;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            minWidth: '800px',
            overflow: 'auto',
            padding: '20px 24px 24px',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Typography variant="h3" sx={() => modalTypographyColor('#3e414d')}>
                {patientData ? patientData?.full_name : null || 'Данные не заполнены'}
              </Typography>

              <Typography variant="subtitle1" sx={() => modalTypographyColor('rgba(62, 65, 77, 0.6)')}>
                {`${patientData ? patientData?.company : null} / (ID: ${
                  patientData ? patientData.id : null || 'Данные не заполнены'
                }) `}
              </Typography>
            </Box>
            <Stack direction="row" gap="16px" alignItems="center">
              <Typography variant="subtitle1" sx={() => modalTypographyColor('#ff6d6d')}>
                {(() => {
                  if (isPatientTypeProposalDetach) {
                    const date = format(new Date(proposalDetachmentActionData?.data?.detached_at), 'dd.MM.yyyy');
                    return `Пациент подан на открепление с ${date}`;
                  }
                  if (patientData?.scheduled_program_changes) {
                    const date = format(new Date(patientData.scheduled_program_changes.change_at), 'dd.MM.yyyy');
                    return `Программа будет сменена на ${patientData.scheduled_program_changes.title} с ${date}`;
                  }
                  if (patientData?.type !== 'Завершенный') {
                    return `${patientData?.type || 'Данные не заполнены'} c ${
                      patientData?.start_date || 'Данные не заполнены'
                    }`;
                  }
                  if (patientData?.type === 'Завершенный') {
                    return `${patientData?.type || 'Данные не заполнены'} c ${
                      patientData?.end_date || 'Данные не заполнены'
                    }`;
                  }
                  return `${patientData?.type || 'Данные не заполнены'} c ${
                    patientData?.start_date || 'Данные не заполнены'
                  }`;
                })()}
              </Typography>
              <Box
                sx={{
                  color: themeColor,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={() => modalTypographyColor('#ff6d6d')}
                  onClick={() => {
                    refetchCompanyActions();
                    handleClose();
                    setHistoryActionsModal(true);
                  }}
                >
                  История операций
                </Typography>
              </Box>
            </Stack>
          </Box>
          <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="subtitle2"
            sx={{
              margin: '0 4px 0 0',
              color: 'rgba(62, 65, 77, 0.6)',
            }}
          >
            Статус приветственного письма:&#32;
          </Typography>

          <Typography variant="subtitle1" sx={{ margin: '0 18px 0 0', color: '#3e414d' }}>
            {patientData?.welcome_newsletter_status || 'Данные не заполнены'}
          </Typography>

          {isAccessAllowed(patientData?.companyUuid, 'HR_slots_welcomemail', accessList, defaultPermissions) &&
            isAccessAllowed(patientData?.companyUuid, 'HR_slots', accessList, defaultPermissions) && (
              <MainButton
                disabled={patientData ? patientData.type !== 'Активный' : !!patientData}
                textButton="Отправить еще раз"
                icon={<EmailOutlinedIcon sx={{ width: '16px' }} />}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                handleClick={handleSendWelcomeLetter}
              />
            )}
        </Box>

        {searchRelativePatientsData?.patients?.length > 0 && (
          <Box
            sx={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              color: theme.palette.primary.main,
            }}
          >
            Имеет родственные связи
          </Box>
        )}

        <Box sx={{ maxHeight: '600px', overflowX: 'hidden', overflowY: 'scroll' }}>
          <Box sx={{ marginTop: '40px' }}>
            <Typography variant="h4" sx={{ margin: '0 0 12px', color: '#3e414d' }}>
              Персональная информация
            </Typography>

            <Box sx={modalBlockRowStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Гражданство
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.nationality || 'Данные не заполнены'}
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Адрес фактического проживания
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.address || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>

            <Box sx={modalBlockBorderStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Дата рождения
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.birth_date || 'Данные не заполнены'}
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Пол
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.gender || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>

            <Box sx={modalBlockBorderStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Телефон
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.phone || 'Данные не заполнены'}
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  E-mail
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.email || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>

            <Box sx={modalBlockRowStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Паспорт
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.passport_number || 'Данные не заполнены'}
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Дата выдачи
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.passport_date || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>

            <Box sx={modalBlockBorderStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Кем выдан
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.passport_given || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>

            <Box sx={modalBlockRowStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Должность
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.position || 'Данные не заполнены'}
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Табельный номер
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.number || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginTop: '40px' }}>
            <Typography variant="h4" sx={{ margin: '0 0 12px', color: '#3e414d' }}>
              Программа
            </Typography>

            <Box sx={modalBlockRowStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Наименование
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.program_title || 'Данные не заполнены'}
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Период обслуживания
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.program_period || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>

            <Box sx={modalBlockRowStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Объем
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.service_types?.map((item) => item.title).join(' ,') || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>

            <Box sx={modalBlockRowStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Номер полиса страховщика
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.policy_number || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>

            <Box sx={modalBlockRowStyle}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                  Доп. информация
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#3e414d' }}>
                  {patientData?.additional_info || 'Данные не заполнены'}
                </Typography>
              </Box>
            </Box>
          </Box>

          {searchRelativePatientsData?.patients?.length > 0 && (
            <Box sx={{ marginTop: '40px' }}>
              <Typography variant="h4" sx={{ margin: '0 0 12px', color: '#3e414d' }}>
                Родственники
              </Typography>
              <Box>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                  }}
                >
                  <Stack
                    sx={{
                      minWidth: '33%',
                    }}
                  >
                    <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                      Фио
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      minWidth: '33%',
                    }}
                  >
                    <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                      Дата рождения
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      minWidth: '33%',
                    }}
                  >
                    <Typography variant="h5" sx={{ color: 'rgba(62, 65, 77, 0.6)' }}>
                      Родственная связь
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  {searchRelativePatientsData?.patients?.map((patient: SearchRelativePatient) => (
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        onClick={() => {
                          setSlotRelative(patient);
                          setSlotsModalOpen(true);
                          handleClose();
                        }}
                        sx={{
                          color: theme.palette.primary.main,
                          width: '33%',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {patient?.fullName}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: '#3e414d', width: '33%' }}>
                        {getFormattedDate(patient?.birthDate, 'dd.MM.yyyy')}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: '#3e414d', width: '33%' }}>
                        {patient?.relativeType}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Box>
          )}

          {isAccessAllowed(patientData?.companyUuid, 'HR_slots_docs', accessList, defaultPermissions) &&
            isAccessAllowed(patientData?.companyUuid, 'HR_slots', accessList, defaultPermissions) && (
              <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h4" sx={{ margin: '0 0 12px', color: '#3e414d' }}>
                  Документы
                </Typography>

                <Box
                  className="modal-info-staff__block-row modal-info-staff__block-row_type_btns"
                  sx={{
                    marginBottom: '8px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '16px',
                  }}
                >
                  <MainButton
                    textButton={`Полис ${getCompanyName()}`}
                    icon={<FavoriteOutlinedIcon sx={{ width: '16px' }} />}
                    border
                    handleClick={handlePolicyButtonClick}
                  />
                  <MainButton
                    textButton="Мед. программа"
                    icon={<FileCopyIcon sx={{ width: '16px' }} />}
                    border
                    handleClick={() => {
                      window.open(patientData?.medProgram);
                    }}
                  />
                  {patientData?.vzrPolicy !== null && (
                    <MainButton
                      textButton="Полис ВЗР"
                      icon={<FlightTakeoffOutlinedIcon sx={{ width: '16px' }} />}
                      border
                      handleClick={() => window.open(patientData?.vzrPolicy)}
                    />
                  )}
                  <MainButton
                    textButton="Список клиник"
                    icon={<FileCopyIcon sx={{ width: '16px' }} />}
                    border
                    handleClick={handleLoadClinicsList}
                  />
                </Box>
              </Box>
            )}
        </Box>

        <Box sx={modalBlockFooterStyle}>
          {isPatientTypeSlot &&
            !isPatientTypeProposalDetach &&
            isAccessAllowed(
              patientData?.companyUuid,
              'HR_slots_action_changeprogram',
              accessList,
              defaultPermissions
            ) &&
            isAccessAllowed(patientData?.companyUuid, 'HR_slots', accessList, defaultPermissions) && (
              <TooltipActionsInfo placement="left" title={tooltipTitle}>
                <Box>
                  <MainButton
                    textButton="Редактировать программу"
                    handleClick={() => setIsChangeProgramModal(true)}
                    disabled={
                      isLoadingSearchPrograms ||
                      !patientProgram ||
                      patientData?.scheduled_program_changes !== null ||
                      patientData?.type === 'Завершенный' ||
                      isAddingForbiddenByDates
                    }
                  />
                </Box>
              </TooltipActionsInfo>
            )}

          {isPatientTypeSlot &&
            !isPatientTypeProposalDetach &&
            isAccessAllowed(patientData?.companyUuid, 'HR_slots_action_attach', accessList, defaultPermissions) &&
            isAccessAllowed(patientData?.companyUuid, 'HR_slots', accessList, defaultPermissions) && (
              <TooltipActionsInfo placement="left" title={getDetachTooltipTitle(isDetachingForbiddenByDates) || ''}>
                <Box component="span">
                  <MainButton
                    textButton="Открепить"
                    handleClick={() => {
                      handleClose();
                      setDetachPatientModal(true);
                    }}
                    disabled={
                      (patientData ? patientData.type === 'Завершенный' : !!patientData) ||
                      isLoadingServicePeriods ||
                      !periodByAttachOrDetach ||
                      isDetachingForbiddenByDates
                    }
                  />
                </Box>
              </TooltipActionsInfo>
            )}

          {!isPatientTypeProposalDetach &&
            isAccessAllowed(patientData?.companyUuid, 'HR_slots', accessList, defaultPermissions) &&
            isAccessAllowed(patientData?.companyUuid, 'HR_slots_action_edit', accessList, defaultPermissions) && (
              <TooltipActionsInfo
                placement="left"
                title={
                  patientData?.type === 'Завершенный' ? 'Нельзя редактировать пациентов с завершенным периодом' : ''
                }
              >
                <Box component="span">
                  <MainButton
                    textButton="Редактировать"
                    disabled={patientData ? patientData.type === 'Завершенный' : !!patientData}
                    handleClick={handleEditButtonClick}
                  />
                </Box>
              </TooltipActionsInfo>
            )}

          {!isPatientTypeSlot && !isPatientTypeProposalDetach && (
            <MainButton
              textButton={
                <>
                  Активировать сразу{' '}
                  <TooltipActionsWarning
                    placement="top"
                    title={
                      <>
                        <Box
                          component="p"
                          sx={{
                            fontSize: '10px',
                            lineHeight: '12px',
                            letterSpacing: '0.02em',
                            textAlign: 'left',
                          }}
                        >
                          Если вам необходимо получить документы по прикреплению сразу, вы можете принудительно
                          активировать прикрепление
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            fontSize: '10px',
                            lineHeight: '12px',
                            letterSpacing: '0.02em',
                            textAlign: 'left',
                          }}
                        >
                          Обратите внимание, что отменить действие или скорректировать данные будет невозможно
                        </Box>
                      </>
                    }
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={{
                        color: themeColor,
                        height: '16px',
                        width: '16px',
                        transform: 'translateY(-60%) translateX(10%)',
                      }}
                    />
                  </TooltipActionsWarning>
                </>
              }
              handleClick={() => setEarlyAttachWarning(true)}
            />
          )}

          {!isPatientTypeSlot && !isPatientTypeProposalDetach && (
            <MainButton
              textButton={
                <>
                  Отменить{' '}
                  <TooltipActionsWarning
                    placement="top"
                    title={
                      <Box
                        component="p"
                        sx={{
                          fontSize: '10px',
                          lineHeight: '12px',
                          letterSpacing: '0.02em',
                          textAlign: 'left',
                        }}
                      >
                        Действие будет отменено без отображения в документах
                      </Box>
                    }
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={{
                        color: themeColor,
                        height: '16px',
                        width: '16px',
                        transform: 'translateY(-60%) translateX(10%)',
                      }}
                    />
                  </TooltipActionsWarning>
                </>
              }
              handleClick={() => setCancelAttachWarning(true)}
            />
          )}
          {isPatientTypeProposalDetach && (
            <>
              <MainButton
                textButton={
                  <>
                    Восстановить прикрепление
                    <TooltipActionsWarning
                      placement="top"
                      title={
                        <>
                          {' '}
                          <Box
                            component="p"
                            sx={{
                              fontSize: '10px',
                              lineHeight: '12px',
                              letterSpacing: '0.02em',
                              textAlign: 'left',
                            }}
                          >
                            Пациент уже подан на открепление с{' '}
                            {format(new Date(proposalDetachmentActionData?.data?.detached_at), 'dd.MM.yyyy')}{' '}
                          </Box>
                          <Box
                            component="p"
                            sx={{
                              fontSize: '10px',
                              lineHeight: '12px',
                              letterSpacing: '0.02em',
                              textAlign: 'left',
                            }}
                          >
                            Обратите внимание, что данное действие отменит открепление без отображения в документах.
                          </Box>
                        </>
                      }
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{
                          color: themeColor,
                          height: '16px',
                          width: '16px',
                          transform: 'translateY(-60%) translateX(10%)',
                        }}
                      />
                    </TooltipActionsWarning>
                  </>
                }
                handleClick={() => handleCancelDetachment(proposalDetachmentActionData?.data?.base_action?.uuid)}
              />
              <MainButton
                textButton={
                  <>
                    Изменить детали открепления
                    <TooltipActionsWarning
                      placement="top"
                      title={
                        <Box
                          component="p"
                          sx={{
                            fontSize: '10px',
                            lineHeight: '12px',
                            letterSpacing: '0.02em',
                            textAlign: 'left',
                          }}
                        >
                          Обратите внимание, что пациент уже подан на открепление c{' '}
                          {format(new Date(proposalDetachmentActionData?.data?.detached_at), 'dd.MM.yyyy')}, повторное
                          действие скорректирует дату откреплениям без отображения в документах
                        </Box>
                      }
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{
                          color: themeColor,
                          height: '16px',
                          width: '16px',
                          transform: 'translateY(-60%) translateX(10%)',
                        }}
                      />
                    </TooltipActionsWarning>
                  </>
                }
                handleClick={() => {
                  setDetachPatientModal(true);
                  setEditDetachModal(true);
                }}
              />
            </>
          )}

          <MainButton textButton="Закрыть" mainBtn handleClick={handleClose} />
        </Box>
      </Dialog>

      {!!patientData && (
        <DetachPatientModal
          open={isDetachPatientModal}
          isEditDetachModal={isEditDetachModal}
          massDetach={false}
          patientData={patientData}
          detachDate={proposalDetachmentActionData?.data?.detached_at}
          handleClose={() => {
            setDetachPatientModal(false);
            setEditDetachModal(false);
          }}
        />
      )}

      {!!patientData && (
        <HistoryActionsModal
          open={isHistoryActionsModal}
          patientData={patientData}
          historyActions={CompanyActionsData || []}
          isLoadingCompanyActions={isLoadingCompanyActions}
          hasNextPageCompanyActions={hasNextPageCompanyActions}
          isFetchingNextPageCompanyActions={isFetchingNextPageCompanyActions}
          fetchNextPageCompanyActions={fetchNextPageCompanyActions}
          startDate={startDate}
          handleStartDate={setStartDate}
          endDate={endDate}
          handleEndDate={setEndDate}
          eventType={eventType}
          handleEventType={setEventType}
          orderingTableHistory={orderingTableHistory}
          handleOrderingTableHistory={setOrderingTableHistory}
          handleClose={() => setHistoryActionsModal(false)}
        />
      )}

      {!!patientData && (
        <EditPatientModal
          isAddingForbiddenByDates={isAddingForbiddenByDates}
          selectedCompanies={selectedCompanies}
          periodByAttachOrDetach={periodStaffAction}
          patientData={patientData}
          open={isEditPatientModal}
          handleClose={() => setEditPatientModal(false)}
          refetchPatient={refetchPatient}
        />
      )}
      <ChangeProgramModal
        open={isChangeProgramModal}
        handleClose={() => setIsChangeProgramModal(false)}
        periodByAttachOrDetach={periodStaffAction}
        parentClose={handleClose}
        patientData={patientData}
        programs={programs}
        patientProgram={patientProgram}
        refetchPatient={refetchPatient}
      />
      <RelativeSlotsModal
        open={slotsModalOpen}
        handleClose={() => setSlotsModalOpen(false)}
        data={slotRelative}
        accessList={accessList}
        rolesData={rolesData}
        refetchSearchPatients={refetchSearchPatients}
        defaultPermissions={defaultPermissions}
      />
      <EarlyAttachWarning
        open={isEarlyAttachWarning}
        handleClose={() => setEarlyAttachWarning(false)}
        handleClickBtn={() => handleForceAddPatient(patientData?.uuid)}
      />
      <CancelAttachWarning
        open={isCancelAttachWarning}
        handleClose={() => setCancelAttachWarning(false)}
        handleClickBtn={() => {
          handleCancelPatientAttach(patientData?.uuid);
          refetchSearchPatients();
          handleClose();
        }}
      />
      {patientData && searchRelativePatientsData && (
        <EditProposalModal
          patientData={patientData}
          isAddingForbiddenByDates={isAddingForbiddenByDates}
          celeryType="slots"
          open={isEditProposalModal}
          companiesHR={selectedCompanies}
          selectedCompanies={selectedCompanies}
          periodInMainFilter={mainFilterPeriodStaff}
          handleClose={() => setEditProposalModal(false)}
          handleTaskIdAttach={() => null}
          accessList={accessList}
          defaultPermissions={defaultPermissions}
          relativeData={searchRelativePatientsData}
          handleCancelPatientAttach={handleCancelPatientAttach}
          searchRelativePatientsData={searchRelativePatientsData}
          handleCloseParent={handleClose}
        />
      )}
    </>
  );
};
