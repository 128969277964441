import { useQuery } from 'react-query';

import { handleRefreshToken } from 'utils/refreshToken';
import { API_URL, HEADER_ACCEPT_HR } from 'utils/apiConstants';
import { FetchError } from 'utils/FetchError';
import { GetProposalDetachmentActionData, GetProposalDetachmentActionParams } from 'interfaces/hooks/patientsHooksInterfaces';

export const useGetProposalDetachmentAction = ({ onError, uuid }: GetProposalDetachmentActionParams): GetProposalDetachmentActionData => {
    const { data: proposalDetachmentActionData } = useQuery(
        ['proposal_detachment_action', uuid],
        async () => {
            await handleRefreshToken();

            const token = localStorage.getItem('token');

            const res = await fetch(`${API_URL}/employees/${uuid}/detachment_proposal_action_by_slot/`, {
                method: 'GET',
                headers: {
                    Accept: `${HEADER_ACCEPT_HR}`,
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.ok) {
                return res.json();
            }
            throw new FetchError(res);
        },
        {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            enabled: !!uuid,
            onError
        }
    );

    return { proposalDetachmentActionData };
};